import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';

import css from './SearchMapPriceLabel.module.css';

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice =
      currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken ===
      nextProps.mapComponentRefreshToken;

    return !(
      isSameListing &&
      hasSamePrice &&
      hasSameActiveStatus &&
      hasSameRefreshToken
    );
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      listing,
      onListingClicked,
      isActive,
      config,
    } = this.props;
    const currentListing = ensureListing(listing);
    const { price } = currentListing.attributes;
    const listingType = listing.attributes.publicData.listingType;

    // <my old logic> This will be either item, hour, or day --brian c
    // const listingUnitType = listing.attributes.publicData.unitType
    const mapPinUnit = () => {
      if (listingType === 'hourly-booking') {
        return '/hour';
      } else if (listingType === 'daily-booking') {
        return '/day';
      } else if (listingType === 'block-time-booking') {
        return 'starts at/';
      } else if (listingType === 'nightly-booking') {
        return '/night';
      } else return '/each';
    };

    // Create formatted price if currency is known or alternatively show just the unknown currency.
    const formattedPrice =
      price && price.currency === config.currency
        ? formatMoney(intl, price)
        : price?.currency
        ? price.currency
        : null;

    const handleBlockPricing = () => {
      const amount = parseFloat(formattedPrice.slice(1));
      const lowestBlock = Math.min(
        ...listing.attributes.publicData.blockBooking
      );

      const startingAtPrice = `${'$' + amount * lowestBlock}`;
      return startingAtPrice;
    };

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, {
      [css.mapLabelActive]: isActive,
      [css.noPriceSetLabel]: !formattedPrice,
    });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });
    return (
      <button
        className={classes}
        onClick={() => onListingClicked(currentListing)}
      >
        <div className={css.caretShadow} />

        {formattedPrice ? (
          <>
            {listingType === 'block-time-booking' ? (
              <div className={priceLabelClasses}>
                {currentListing.attributes.title}:
                <span style={{ color: '#2ecc71' }}>
                  <span style={{ color: '#0F89E6' }}>{mapPinUnit()}</span>
                  {handleBlockPricing()}
                </span>{' '}
              </div>
            ) : (
              <div className={priceLabelClasses}>
                {currentListing.attributes.title}:
                <span style={{ color: '#2ecc71' }}>
                  {' '}
                  {formattedPrice}
                  {mapPinUnit()}
                </span>
              </div>
            )}
          </>
        ) : (
          <div className={priceLabelClasses}>
            {currentListing.attributes.title}:
            <span style={{ color: '#2ecc71' }}> (inquire)</span>
          </div>
        )}
        <div className={caretClasses} />
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
